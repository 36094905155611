import {useMemo, useRef} from 'react'
import {useLocation} from 'react-router-dom'

import typedObjectKeys from './typedObjectKeys'

// TODO: используется в одном единственном месте - подумать нужна ли она вообще
export function getUrl(url: string | URL, searchParamMap = {}) {
    url = new URL(url)
    populateSearchParams(searchParamMap, url.searchParams)
    return `${url}`
}

export function getSearchString(searchParamMap = {}) {
    return `?${populateSearchParams(searchParamMap)}`
}

export function mergeSearchString(searchParamMap = {}) {
    const searchParams = new URLSearchParams(location.search)

    for (const key of Object.keys(searchParamMap))
        searchParams.delete(key)

    return `?${populateSearchParams(searchParamMap, searchParams)}`
}

function populateSearchParams(searchParamMap: Record<string, unknown>, searchParams = new URLSearchParams) {
    return Object
        .entries(searchParamMap)
        .reduce(
            (searchParams, [name, value]) => {
                if (value !== undefined)
                    for (const singleValue of [value].flat())
                        searchParams.append(name, `${singleValue}`)

                return searchParams
            },
            searchParams,
        )
}

export function getSearchParams(searchString: string) {
    return [...new URLSearchParams(searchString).entries()]
        .reduce<Record<string, string | string[]>>(
            (paramMap, [key, value]) => {
                let existingParam = paramMap[key]

                if (existingParam) {
                    if (!Array.isArray(existingParam))
                        existingParam = paramMap[key] = [existingParam]

                    existingParam.push(value)
                } else
                    paramMap[key] = value

                return paramMap
            },
            {},
        )
}

type ParamDescription = string | never[]

type ParamDescriptions = Record<string, ParamDescription>

type ParamResult<T extends ParamDescription> = T extends never[]
    ? string[]
    : string

export type ParamResults<T extends ParamDescriptions> = {
    [Key in keyof T]: ParamResult<T[Key]>
}

export function useSearchParams<T extends ParamDescriptions>(paramDescriptions: T) {
    const location = useLocation()
    const paramDescriptionsRef = useRef(paramDescriptions)

    return useMemo(
        () => getTypedSearchParams(location.search, paramDescriptionsRef.current),
        [location.search],
    )
}

export function compareSearchParams<T extends string>(
    oldParams: Record<T, string | string[]>,
    newParams: Record<T, string | string[]>,
): T[] {
    const keys = [...new Set([...typedObjectKeys(oldParams), ...typedObjectKeys(newParams)])]

    return keys.filter(key => {
        const oldValue = oldParams[key]
        const newValue = newParams[key]

        if (oldValue == newValue)
            return false

        if (!newValue && !oldValue)
            return false

        if (
            !oldValue && newValue ||
            !newValue && oldValue
        )
            return true

        if (Array.isArray(oldValue))
            return oldValue.length != newValue.length ||
                oldValue.some(value => !newValue.includes(value))

        return true
    })
}

// пример использования useSearchParams
// допустим, адрес страницы /projects?page=5&x=6&x=7
/* eslint-disable */
function Component() {
    const {page, x, y, z} = useSearchParams({
        page: '1',
        x: [],
        y: [],
        z: 'abc',
    })

    console.log(page) // '5'
    console.log(x) // ['6', '7']
    console.log(y) // []
    console.log(z) // 'abc'
}
/* eslint-enable */

export function getTypedSearchParams<T extends ParamDescriptions>(
    searchString: string,
    paramDescriptions: T,
) {
    const searchParams = new URLSearchParams(searchString)

    const typedParams = Object.fromEntries(
        Object.entries(paramDescriptions)
            .map(([name, defaultValue]) => {
                const value = Array.isArray(defaultValue)
                    ? searchParams.getAll(name)
                    : searchParams.get(name) || defaultValue

                return [name, value]
            }),
    ) as ParamResults<T>

    return {
        ...getSearchParams(searchString),
        ...typedParams,
    }
}
