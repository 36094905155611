import type {SVGProps} from 'react'

type Props = SVGProps<SVGSVGElement> & {
    // допустимо любое имя файла из src/assets/icons
    name: string
}

export default function({name, ...props}: Props) {
    return (
        <svg
            role='graphics-symbol'
            {...props}
        >
            <use href={`/assets/sprite.svg#${name}-usage`}/>
        </svg>
    )
}
