import {getUseSharedReducer} from './getUseSharedReducer'

/**
 * Создает хук аналогичный useState с разделяемым состоянием
 *
 * @param initial начальное состояние
 */
export function getUseSharedState<State>(initial: State) {
    function reducer(state: State, newState: State) {
        return newState
    }

    return getUseSharedReducer(reducer, initial)
}

// Пример использования
// Оба компонента разделяют общий счетчик useCounter

const useCounter = getUseSharedState(0)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Comp1() {
    const [counter, setCounter] = useCounter()
    return (
        <button onClick={() => setCounter(counter + 1)}>
            {counter}
        </button>
    )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Comp2() {
    const [counter, setCounter] = useCounter()
    return (
        <button onClick={() => setCounter(counter * 2)}>
            {counter}
        </button>
    )
}
