import Progress from './Progress'

export default function OverallProgress({message}: {message?: string}) {
    return (
        <div className='progress-overlay'>
            <Progress/>
            <small className='progress-message text text_center text_grey-light'>
                {message}
            </small>
        </div>
    )
}
