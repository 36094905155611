import {PrimeReactProvider} from 'primereact/api'
import React from 'react'
import {createRoot} from 'react-dom/client'

import OverallProgress from './components/OverallProgress'
import {loadLocale} from './services/localization'
import {showPopup} from './services/popup'

import 'reset-css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

import './styles/index.scss'
import 'core-js/features/math/clamp'
import 'core-js/features/array/with'
import 'core-js/features/array/to-reversed'
import 'core-js/features/array/to-sorted'
import 'core-js/features/array/to-spliced'
import 'core-js/features/object/group-by'

require.context('./assets/icons/', false, /\.svg$/)

const rootElement = document.querySelector('#root') as Element
const root = createRoot(rootElement)

root.render(
    <div className='page'>
        <OverallProgress/>
    </div>
)

loadLocale()
    .catch(() => showPopup('Localization failure', {type: 'error'}))
    .then(() => import('./components/App'))
    .then(appModule => appModule.default)
    .then(App => {
        root.render(
            <React.StrictMode>
                <PrimeReactProvider>
                    <App/>
                </PrimeReactProvider>
            </React.StrictMode>
        )
    })
    .catch(() => showPopup('Error loading app', {type: 'error'}))
