import {trl, trlCount, trlObject} from '~/services/localization'

import type {BackendError} from '~/services/error'

export const DURATION_RATIOS = {
    ms: 1,
    s: 1000,
    min: 60 * 1000,
    h: 60 * 60 * 1000,
}

export function formatCount(value: number): string {
    if (isNaN(value))
        return ''

    return value.toLocaleString(trl`_локаль`, {maximumSignificantDigits: 3, notation: 'compact'})
}

const byteUnits = [
    'byte',
    'kilobyte',
    'megabyte',
    'gigabyte',
    'terabyte',
    'petabyte',
]

export function formatSize(value: number) {
    if (isNaN(value))
        return ''

    const exponent = Math.log(Math.abs(value)) / Math.log(1024) | 0

    value /= 1024 ** exponent

    return value.toLocaleString(trl`_локаль`, {
        maximumSignificantDigits: exponent == 0 ? 4 : 3,
        useGrouping: false,
        style: 'unit',
        unit: byteUnits[exponent],
    })
}

export const formatDate = (value: string | number | Date) => new Date(value)
    .toLocaleString(trl`_локаль`, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    })

export const formatDateShort = (value: string | number | Date) => new Date(value)
    .toLocaleString(trl`_локаль`, {
        year: 'numeric',
        month: 'numeric',
        day: '2-digit',
    })

export const formatMonthYear = (value: string | number | Date) => new Date(value)
    .toLocaleString(trl`_локаль`, {
        year: 'numeric',
        month: 'long',
    })

export const formatDayMonth = (value: string | number | Date) => new Date(value)
    .toLocaleString(trl`_локаль`, {
        month: 'long',
        day: '2-digit',
    })

export const formatDateTime = (value: string | number | Date) => new Date(value)
    .toLocaleString(trl`_локаль`, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    })

export const formatMessageTime = (value: string | number | Date) => new Date(value)
    .toLocaleString(trl`_локаль`, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    })

export const formatMessageTimeWithDate = (value: string | number | Date) => new Date(value)
    .toLocaleString(trl`_локаль`, {
        dateStyle: 'short',
        timeStyle: 'medium',
    })

export const secondsToDate = (seconds: number) => {
    const date = new Date('1995-12-17T00:00:00')
    date.setSeconds(seconds | 0)
    return date
}

export const formatUnitNumber = (x: number, format: string) =>
    x.toLocaleString(trl`_локаль`, {style: 'unit', unit: format, unitDisplay: 'short', maximumFractionDigits: 2})

export const formatNumber = (value: number, maximumFractionDigits?: number) =>
    value.toLocaleString(trl`_локаль`, {maximumFractionDigits})

export const formatInteger = (value: number) =>
    value.toLocaleString(trl`_локаль`, {maximumFractionDigits: 0})

export const formatPercent = (value: number, maximumFractionDigits?: number) => value
    .toLocaleString(trl`_локаль`, {
        style: 'percent',
        maximumFractionDigits,
    })
    .replace('\xa0%', '%')

export const formatError = (error: BackendError) => {
    const key = `_ошибка.${error.code}`
    return trlObject(key)
        ? trl(key)
        : error.message || ''
}

export const formatDuration = (milliseconds: number) => {
    const date = new Date(milliseconds)

    const days = Math.floor(milliseconds / DURATION_RATIOS.h / 24)
    const hours = date.getUTCHours()
    const minutes = date.getUTCMinutes()
    const seconds = date.getUTCSeconds()

    const key = days
        ? '_формат.продолжительность.дни'
        : hours
            ? '_формат.продолжительность.часы'
            : minutes
                ? '_формат.продолжительность.минуты'
                : '_формат.продолжительность.секунды'

    return trl(key, seconds, minutes, hours, days)
}

export const formatMillisecondsToTime = (milliseconds: number) => [
    durationFormatHelper(milliseconds / DURATION_RATIOS.h),
    durationFormatHelper(milliseconds / DURATION_RATIOS.min % 60),
    durationFormatHelper(milliseconds / DURATION_RATIOS.s % 60),
].join(':')

export const formatMillisecondsToTimeShort = (milliseconds: number) => [
    ...milliseconds / DURATION_RATIOS.h > 1 ? [durationFormatHelper(milliseconds / DURATION_RATIOS.h)] : '',
    durationFormatHelper(milliseconds / DURATION_RATIOS.min % 60),
    durationFormatHelper(milliseconds / DURATION_RATIOS.s % 60),
].join(':')

export const durationFormatHelper = (x: number) =>
    Math.floor(x).toLocaleString(undefined, {minimumIntegerDigits: 2})

const durations = {
    s: 1,
    m: 60,
    h: 60 * 60,
    d: 60 * 60 * 24,
    w: 60 * 60 * 24 * 7,
    M: 60 * 60 * 24 * 30,
    y: 60 * 60 * 24 * 365,
}

const units = {
    s: {
        limit: durations.s * 60,
        divisor: durations.s,
    },
    m: {
        limit: durations.m * 90,
        divisor: durations.m,
    },
    h: {
        limit: durations.h * 36,
        divisor: durations.h,
    },
    d: {
        limit: durations.d * 10,
        divisor: durations.d,
    },
    w: {
        limit: durations.w * 6,
        divisor: durations.w,
    },
    M: {
        limit: durations.M * 24,
        divisor: durations.M,
    },
    y: {
        limit: Infinity,
        divisor: durations.y,
    },
}

export const formatAge = (date: Date | number | string) => {
    const seconds = (Date.now() - +new Date(date)) / 1000

    if (seconds < 0 || isNaN(seconds))
        return ''

    const [unit, {divisor}] = Object.entries(units).find(([, {limit}]) => seconds < limit) || ['s', {divisor: 1}]

    return trlCount(`age.${unit}.%0`, seconds / divisor | 0)
}

export function formatExportDate(date: Date) {
    return date
        .toLocaleDateString()
        .replaceAll('/', '.')
}
