import {useAuth} from './user'

export type BackendError = {
    code: number
    message?: string
}

export const enum ErrorCodes {
    accessDenied = 11,
    notFoundCatalog = 40,
    // clusterError = 41,
    // projectWithoutType = 49,
    // noCategoryModel = 300,
    // noFreeSpace = 500,
    // weightScriptNotFound = 3100,
    scriptCompileError = 5250,
    voiceProjectNotFound = 4103,
    wrongProjectType = 3400,
    somethingGoneWrong = -1000,
    mediaLoadError = -1001,
    wrongUsernameOrPassword = -1002,
    fileAlreadyExists = -1003,
    filterQueryInvalid = -1004,
    userCancel = -1005,
    noChecklist = -1007,
}

export function handleHttpError(httpResponse?: {error: {code?: number}}): never {
    const error = httpResponse?.error || {}
    const code = error.code || ErrorCodes.somethingGoneWrong

    if (code == ErrorCodes.accessDenied)
        useAuth.dispatch({type: 'logout'})

    throw {
        ...error,
        code,
    }
}
